<template>
    <div>
        <v-card flat tile>
            <v-card-title>
                {{$t('settings.lang_editCMITerminal')}}
            </v-card-title>
            <v-divider class="ma-0 pa-0"/>
            <v-form v-model="valid" ref="form">
                <v-container>
                    <v-row>
                        <v-col lg="4" md="6" sm="12" cols="12">
                <v-select :items="kassenIDs" :label="$t('generic.lang_cashierID')" v-model="cashierID" outlined>
                </v-select>
              </v-col>
              <v-col lg="4" md="6" sm="12" cols="12">
                <v-text-field
                    v-model="terminalAliasName"
                    :label="$t('generic.lang_alias')"
                    @focus="showTouchKeyboard"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    autocomplete="off"
                    outlined
                    required
                    :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col lg="4" md="6" sm="12" cols="12">
                    <v-text-field
                    v-model="outlet_id"
                    :label="$t('generic.lang_outlet_id')"
                    @focus="showTouchKeyboard"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    autocomplete="off"
                    outlined
                    required
                    :rules="[rules.required]"
                />
                  </v-col>
                  <v-col lg="4" md="6" sm="12" cols="12">
                    <v-text-field
                    v-model="terminal_id"
                    :label="$t('generic.lang_terminal_id')"
                    @focus="showTouchKeyboard"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    autocomplete="off"
                    outlined
                    required
                    :rules="[rules.required]"
                />
                  </v-col>
                  <v-col lg="4" md="6" sm="12" cols="12">
                    <v-text-field
                    v-model="ecr_number"
                    :label="$t('generic.lang_ecr_number')"
                    @focus="showTouchKeyboard"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    autocomplete="off"
                    outlined
                    required
                    :rules="[rules.required]"
                />
                  </v-col>
                  <v-col lg="4" md="6" sm="12" cols="12">
                    <v-text-field
                    v-model="cachier_id_CMI"
                    :label="$t('generic.lang_cmi_cachier_id')"
                    @focus="showTouchKeyboard"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    autocomplete="off"
                    outlined
                    required
                    :rules="[rules.required]"
                />
                  </v-col>
                    </v-row>
                    <v-row no-gutters justify-md="end" justify="center">
                        <v-btn color="error" large :loading="loading" :disabled="!valid" @click="deleteData()">
                            {{$t('generic.lang_delete')}}
                        </v-btn>
                        <v-btn color="success" large :loading="loading" :disabled="!valid" @click="update()">
                          {{$t('generic.lang_edit')}}
                        </v-btn>
                    </v-row>
                </v-container>
            </v-form>
        </v-card>
        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                                :defaultKeySet="touchKeyboard.keySet"
                                :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                                :options="touchKeyboard.options" class="internalWidthExpanded"
                                id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
        </div>
    </div>
</template>

<script>
    import {Events} from "../../../../plugins/events";
    import mixin from "../../../../mixins/KeyboardMixIns"
    import {mapState} from "vuex";
    import validation from "@/mixins/validation/validation";
    export default {
        name: "EditCMIECTerminalComponent",
        mixins: [mixin, validation],
        computed: {
            ...mapState([
                'cashierIDs',
            ]),
        },
        data() {
            return {
                id: null,
                loading: false,
                valid: false,
                kassenIDs: [],
                terminalAliasName: null,
                cashierID: null,
                terminalID: null,
                outlet_id: null,
                terminal_id: null,
                ecr_number: null,
                cachier_id_CMI: null,
            }
        },
        methods: {
            getData() {
                this.id = parseInt(this.$route.params.id);
                this.loading = true;
                this.axios.post('get/settings/editECTerminal/', {
                    ecTerminalID: this.id,
                }).then(res => {
                    this.cashierID = res.data.formfillData.textFields.cashierID;
                    this.terminalAliasName = res.data.formfillData.textFields.terminalAliasName;
                    this.outlet_id = res.data.formfillData.textFields.outlet_id;
                    this.terminal_id = res.data.formfillData.textFields.terminal_id;
                    this.ecr_number = res.data.formfillData.textFields.ecr_number;
                    this.cachier_id_CMI = res.data.formfillData.textFields.cachier_id_CMI;
                    
                }).catch(err => {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_error'),
                        color: "error"
                    });
                }).finally(()=>{
                    this.loading = false;
                })

            },
            update() {


                this.loading = true;
                this.axios.post('update/settings/ecTerminal/', {
                    ecTerminalID: this.id,
                    cashierID: this.cashierID,
        terminalType: 4,
        terminalAliasName: this.terminalAliasName,
        outlet_id: this.outlet_id,
        terminal_id: this.terminal_id,
        ecr_number: this.ecr_number,
        cachier_id_CMI: this.cachier_id_CMI
                }).then(res => {
                    Events.$emit("showSnackbar", {
                        message: this.$t('settings.lang_terminalAdded'),
                        color: "success"
                    });
                    this.$refs.form.reset();
                    this.$router.go(-1)
                    this.loading = false;

                }).catch(err => {
                    Events.$emit("showSnackbar", {
                        message: err.message,
                        color: "error"
                    });
                    this.loading = false;
                })
            },
            deleteData(idsToDelete = []) {
                let self = this;
                this.loading = true;
                this.$swal({
                    title: this.$t('generic.lang_deleteProject'),
                    text: this.$t('generic.lang_deleteConfirmationMSG')+'?',
                    icon: "warning",
                    cancelButtonText: this.$t('generic.lang_cancel'),
                    confirmButtonText: this.$t('generic.lang_delete'),
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
                            idsToDelete = [];
                            idsToDelete.push(this.id);
                        }
                        this.axios.post('delete/settings/ecTerminal/', {
                            ecTerminalIDs: idsToDelete
                        }).then((res) => {
                            if (res.data.status === 'SUCCESS') {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('settings.lang_configsDeleted'),
                                    color: "success"
                                });
                                this.$router.go(-1);
                            } else {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('generic.lang_errorOccurred'),
                                    color: "error"
                                });
                            }
                            this.dialog = false;
                            this.loading = false;
                        });
                    },
                    allowOutsideClick: () => !this.$swal.isLoading,
                });
            },
        },
        mounted() {
            this.kassenIDs = this.cashierIDs.availableCashierIDs;
            this.getData();

        }
    }
</script>

<style scoped>

</style>
