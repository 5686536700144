<template>
    <div>
        <page-title :icon=icon :heading="$t('generic.lang_nav_systemSettings')" :subheading="$t('generic.lang_nav_systemSettings')" show-previous-button></page-title>
        <div class="app-main__inner">
            <EditCMIECTerminalComponent/>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import EditCMIECTerminalComponent from "../../../components/settings/sysetmdata/Payment/EditCMIECTerminalComponent";

    export default {
        name: "EditCMIECTerminal",
        components: {
            EditCMIECTerminalComponent,
            PageTitle,
        },

        data: () => ({
            icon: 'pe-7s-settings icon-gradient bg-tempting-azure',
        })
    }
</script>

